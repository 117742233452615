<template>
  <div id="app">
    <b-container fluid> </b-container>
    <b-container fluid class="text-center">
      <b-row
        class="h-100 align-content-center"
        align-v="center"
        align-h="center"
      >
        <b-col cols="12">
          <img
            src="../assets/logo-esiea.svg"
            alt="Groupe ESIEA"
            class="d-block mx-auto header-logo"
          />
        </b-col>
        <b-col cols="12" class="my-3 my-md-5">
          <h1 class="font-weight-bold">
            Bienvenue aux conférences Warm-Up !
          </h1>
          <h5>Une journée de présentations et d’échanges 100% en ligne avec les recruteurs des entreprises partenaires de l’ESIEA</h5>
          <h5>{{ dateText }}</h5>
        </b-col>

        <b-col cols="12">
          <b-row align-h="around" class="my-3 my-md-2">
            <b-col
              cols="10"
              sm="9"
              md="5"
              xl="4"
              class="mb-4 mb-md-0"
            >
              <router-link to="/jpo/esiea" class="btn btn-lg btn-primary">
                Accéder aux conférences
              </router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="text-center py-3 mt-4" id="footer">
      © Tous droits réservés - ESIEA Groupe -
      <a href="https://www.esiea.fr/accueil/mentions-legales/" target="_blank"
        >Mentions légales</a
      >
    </b-container>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "Home",
  computed: {
    db() {
      return this.$store.getters.db;
    },
    dateText() {
      return (
        moment(this.db.date_debut)
          .tz("Europe/Paris")
          .locale("fr")
          .format("H[H]mm") +
        " " +
        moment(this.db.date_fin)
          .tz("Europe/Paris")
          .locale("fr")
          .format("- H[H]mm")
      );
    },
  },
  mounted() {
    this.$gtag.pageview({
      page_title: "Accueil",
      page_path: '/' + window.location.hash
    });
  },
};
</script>

<style scoped>
.header-logo {
  width: 18rem;
}
</style>
