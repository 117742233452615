<template>
  <div
    id="app"
    :class="{ jpo: typeNav === 'libre', [slug]: true }"
    v-if="db"
  >
    <CampusHeader
      @back="back"
      @menu="menuToggle = true"
    />
    <NavGuide
      :zoneData="zoneData"
      :selectedZone="selectedZone"
      :menu.sync="menuToggle"
      @click="pickZone"
      v-if="zoneData"
    />
    <CampusFooter />
  </div>
</template>

<script>
import CampusFooter from "../components/CampusFooter.vue";
import CampusHeader from "../components/CampusHeader.vue";
import NavGuide from "../components/NavGuide.vue";

export default {
  name: "Jpo",
  components: {
    CampusFooter,
    CampusHeader,
    NavGuide,
  },

  data() {
    return {
      typeNav: null,
      zone: null,
      selectedZone: null,
      menuToggle: false,
    };
  },

  computed: {
    db() {
      return this.$store.getters.db;
    },
    slug() {
      return 'esiea';
    },
    zoneData() {
      if(!this.db.zone) return null;

      let i = this.db.zone.findIndex((e) => e.code === this.zone);

      if (!this.db.zone[i]) return null;

      let salles = this.db.zone[i].salles.map((x) => x);

      return Object.assign({}, this.db.zone[i], { salles: salles });
    }
  },

  watch: {
    db() {
      this.zone = this.db.zone[0].code;
    }
  },

  beforeMount() {
    this.$gtag.event("campus_selected", {
        event_category: "engagement",
        event_label: this.slug
      });
  },

  methods: {
    pickZone(zone) {
      this.zone = zone ?? this.selectedZone;
      if ("ontouchstart" in window) this.selectedZone = zone;
    },
    selectZone(zone) {
      this.selectedZone = zone;
    },
    back() {
      if (!this.typeNav) this.$router.replace("/");
      else this.typeNav = null;
    },
  },
};
</script>

<style lang="scss">
.welcome {
  font-size: 1.2em;
  line-height: 1.8;
  max-width: 35rem;
  margin: 0 auto;
}
</style>
