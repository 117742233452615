<template>
  <div id="overlay">
    <Waiting :timer="timer" :startDate="startDate" :endDate="endDate" v-if="waiting" />
    <router-view v-if="!waiting" />
  </div>
</template>

<script>
import Waiting from "./components/Waiting.vue";
import moment from "moment";

export default {
  components: { Waiting },
  name: "App",
  data() {
    return {
      currentDate: new Date(),
    };
  },
  mounted() {
    this.initTicker();
  },
  computed: {
    db() {
      return this.$store.getters.db;
    },
    serverDate() {
      return new Date(this.db.date_actuel);
    },
    startDate() {
      let date = new Date(this.db.date_debut);
      return date;
    },
    endDate() {
      let date = new Date(this.db.date_fin);
      return date;
    },
    timer() {
      const start = moment(this.startDate);
      const current = moment(this.currentDate);
      return moment.duration(start.diff(current)).asSeconds();
    },
    waiting() {
      if (
        (process.env.NODE_ENV !== "development" &&
          window.location.hostname !== process.env.VUE_APP_PREPROD_HOSTNAME) ||
        this.$route.path === "/waiting"
      ) {
        return (
          this.currentDate < this.startDate || this.currentDate >= this.endDate
        );
      }
      return false;
    },
    ended() {
      return this.currentDate >= this.endDate;
    },
  },
  watch: {
    serverDate(date) {
      this.currentDate =
        process.env.NODE_ENV === "development" ? new Date() : date;
    },
    $route(to) {
      if (
        (process.env.NODE_ENV === "development" ||
          window.location.hostname === process.env.VUE_APP_PREPROD_HOSTNAME) &&
        to.path === "/waiting"
      ) {
        this.waiting = true;
      }
    },
  },
  methods: {
    initTicker() {
      setInterval(() => {
        this.currentDate.setSeconds(this.currentDate.getSeconds() + 1);
        this.currentDate = new Date(this.currentDate.getTime());
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/styles.scss";

#overlay {
  background-color: #151d34da;
  width: 100%;
  min-height: 100%;
  display: flex;
}
</style>
