<template>
  <b-container fluid class="text-center">
    <b-row class="h-100 py-md-4 pr-md-5" align-h="start" no-gutters>
      <b-col
        cols="12"
        md="3"
        class="text-left align-self-start mt-3 ml-lg-5"
      >
        <div class="burger-menu" :class="{ 'open px-4': menu }">
          <b-btn
            variant="link"
            class="text-white d-block ml-auto my-4"
            @click="closeMenu"
            v-if="menu"
          >
            <font-awesome-icon :icon="['fas', 'times']" fixed-width size="2x" />
          </b-btn>
          <div class="stepper">
            <div
              v-for="zone in steps"
              :key="zone.code"
              :class="'step ' + zone.status"
              :id="zone.code"
              @click="go(zone.code)"
            >
              <div class="circle"></div>
              <h5 class="content text-uppercase">{{ zone.nom }}</h5>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="8" class="h-100 mb-4" style="max-width: 60rem">
        <ContentBox
          :numero="index + 1"
          :title="zoneData.nom"
          :video="zoneData.url && !bis ? zoneData.url : null"
          :tiles="tiles"
          :progress="(current / max) * 100"
        >
          <b-btn
            v-if="previousCode"
            variant="link"
            class="text-uppercase mx-2 my-2 d-md-none"
            @click="previous"
            >Créneau précédent</b-btn
          >
          <b-btn
            v-if="zoneData.url && !bis && this.chatrooms.length"
            variant="secondary"
            class="text-uppercase mx-2 my-2 text-white"
            size="lg"
            @click="discuss"
            >Échanger en direct</b-btn
          >
          <b-btn
            v-for="ctaBtn in cta"
            :key="ctaBtn.code"
            variant="primary"
            class="text-uppercase mx-2 my-2 text-white"
            size="lg"
            :href="ctaBtn.url"
            target="_blank"
          >
            {{ ctaBtn.nom }}</b-btn
          >
          <b-btn
            v-if="nextCode"
            variant="primary"
            class="text-uppercase mx-2 my-2"
            size="lg"
            @click="next"
            >Créneau suivant
            <font-awesome-icon :icon="['fas', 'angle-right']" class="ml-1"
          /></b-btn>
        </ContentBox>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ContentBox from "../components/ContentBox.vue";

export default {
  components: {
    ContentBox,
  },

  props: {
    zoneData: Object,
    menu: Boolean,
  },

  data() {
    return {
      bis: false,
    };
  },

  computed: {
    db() {
      return this.$store.getters.db;
    },
    slug() {
      return 'esiea';
    },
    steps() {
      let completed = true;
      return this.db.zone.map((zone) => {
        if (this.zoneData.nom == zone.nom) completed = false;
        return {
          nom: zone.nom,
          code: zone.code,
          status:
            this.zoneData.nom == zone.nom
              ? "current"
              : completed
              ? "completed"
              : "",
        };
      });
    },
    previousCode() {
      return this.db.zone[this.index - 1]
        ? this.db.zone[this.index - 1].code
        : false;
    },
    nextCode() {
      return this.db.zone[this.index + 1]
        ? this.db.zone[this.index + 1].code
        : false;
    },
    index() {
      return this.db.zone.findIndex((e) => e.nom === this.zoneData.nom);
    },
    chatrooms() {
      return this.zoneData.salles.filter((x) => {
        return !x.ligthbox && !x.cta;
      });
    },
    cta() {
      return this.zoneData.salles.filter((x) => {
        return x.cta;
      });
    },
    max() {
      return this.db.zone.length - 1;
    },
    current() {
      let result = this.index;
      if (this.bis) result += 0.5;
      return result;
    },
    tiles() {
      return this.zoneData.salles.filter((x) => {
        return (
          !x.cta &&
          (!this.zoneData.url ||
            !x.url ||
            x.url.trim() != this.zoneData.url.trim())
        );
      });
    },
  },

  methods: {
    next() {
      if (this.zoneData.url && !this.bis) {
        let result = this.zoneData.salles.filter((x) => {
          return !x.url || x.url.trim() != this.zoneData.url.trim();
        });

        if (result.length > 1) {
          this.bis = true;

          this.$gtag.event("navguide_next", {
            event_category: "engagement",
            event_label: this.zoneData.code,
          });

          return;
        }
      }

      this.$gtag.event("navguide_next", {
        event_category: "engagement",
        event_label: this.zoneData.code + "bis",
      });
      this.go(this.nextCode);
    },
    previous() {
      this.$gtag.event("navguide_previous", {
        event_category: "engagement",
        event_label: this.zoneData.code,
      });

      this.go(this.previousCode);
    },
    go(code) {
      this.bis = false;
      this.$emit("click", code);
      this.$emit("update:menu", false);

      this.$gtag.event("zone_selected", {
        event_category: "engagement",
        event_label: code,
        value: 1,
      });
    },
    discuss() {
      if (this.chatrooms.length === 1) {
        window.open(this.chatrooms[0].url, "_blank");
      } else {
        this.next();
      }
    },
    tempo() {
      this.scrollLocked = true;
      setTimeout(() => {
        this.scrollLocked = false;
      }, 200);
    },
    closeMenu() {
      this.$gtag.event("navguide_menu_close", {
        event_category: "engagement"
      });

      this.$emit('update:menu', false);
    }
  },

  mounted() {
    this.$gtag.pageview({
      page_title: "Navigation guidée",
      page_path: '/' + window.location.hash
    });
  },
};
</script>

<style lang="scss" scoped>
.stepper {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 1rem;
}

.step {
  border-left: 0.3rem solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  display: flex;
}

.step.completed {
  border-color: #fff;
}

/* regular step */
.step .circle {
  background-color: #fff;
  border-radius: 100%;
  width: 1.2rem;
  height: 1.2rem;
  position: relative;
  right: 0.75rem;
}

.step.completed .circle,
.step.current .circle {
  background-color: #fff;
}

.content {
  padding: 0 0 2.5rem;
  margin-bottom: 0;
  line-height: 1;
  font-size: 1.2rem;
}

.step:last-child .content {
  padding-bottom: 0;
}

.step.completed .content,
.step.current .content {
  color: #fff;
}

.step.current .content {
  font-weight: 700;
}

.step:hover .content,
.step:focus .content {
  text-decoration: underline;
  cursor: pointer;
}

.burger-menu.open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #151d34;
  z-index: 1000;
}
</style>
