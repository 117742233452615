  
<template>
  <div class="countdown" loaded>
    <div class="countdown__num">
      <span class="countdown__num--time"><Roller :text="displayDays" /></span>
      <span class="countdown__num--unit">Jours</span>
    </div>
    <div class="countdown__num">
      <span class="countdown__num--time"><Roller :text="displayHours" /></span>
      <span class="countdown__num--unit">Heures</span>
    </div>
    <div class="countdown__num">
      <span class="countdown__num--time"
        ><Roller :text="displayMinutes"
      /></span>
      <span class="countdown__num--unit">Minutes</span>
    </div>
    <div class="countdown__num">
      <span class="countdown__num--time"
        ><Roller :text="displaySeconds"
      /></span>
      <span class="countdown__num--unit">Secondes</span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Roller from "vue-roller";

export default {
  components: {
    Roller,
  },
  props: {
    timer: { type: Number, required: true },
  },
  data() {
    return {
      loaded: false,
      expired: false,
    };
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    },
    END() {
      return moment(this.date);
    },
    duration() {
      return moment.duration(this.timer, 'seconds');
    },
    displaySeconds() {
      return this.formatNum(this.duration.seconds());
    },
    displayMinutes() {
      return this.formatNum(this.duration.minutes());
    },
    displayHours() {
      return this.formatNum(this.duration.hours());
    },
    displayDays() {
      return this.formatNum(Math.floor(this.duration.asDays()));
    }
  },
  methods: {
    formatNum: (num) => (num < 10 ? "0" + num.toString() : num.toString())
  },
};
</script>

<style lang="scss" scoped>
.countdown {
  display: flex;
  justify-content: space-evenly;
  &__num {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 9rem;
    width: 9rem;
    border: 3px solid #fff;
    font-weight: 700;
    text-transform: uppercase;
    &--time {
      color: var(--color-primary);
      font-size: 4.3rem;
    }
    &--unit {
      color: var(--color-gray);
      font-size: 1rem;
    }
  }
}
</style>