import { render, staticRenderFns } from "./NavGuide.vue?vue&type=template&id=07be5af6&scoped=true&"
import script from "./NavGuide.vue?vue&type=script&lang=js&"
export * from "./NavGuide.vue?vue&type=script&lang=js&"
import style0 from "./NavGuide.vue?vue&type=style&index=0&id=07be5af6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07be5af6",
  null
  
)

export default component.exports