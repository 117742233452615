<template>
  <b-container fluid class="text-center py-3 bg-dark" id="footer">
    <b-row>
      <b-col cols="12" class="text-lg-left my-2 my-lg-0" lg
        >
        <a
          href="mailto:relations-entreprises@esiea.fr"
          target="_blank"
          class="text-white ml-3"
          ><font-awesome-icon :icon="['far', 'envelope']" fixed-width />
          relations-entreprises@esiea.fr</a
        ></b-col
      >
      <b-col cols="12" lg="auto">
        © Tous droits réservés - ESIEA Groupe -
        <a
          href="https://www.esiea.fr/accueil/mentions-legales/"
          target="_blank"
          class="text-white"
          >Mentions légales</a
        >
      </b-col>
      <b-col
        cols="12"
        lg
        class="text-center text-lg-right my-2 my-lg-0"
      >
        <a href="https://www.facebook.com/esiea" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'facebook-f']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
        <a href="https://www.instagram.com/esiea_groupe" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'instagram']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
        <a href="https://twitter.com/GroupeESIEA" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'twitter']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
        <a href="https://www.linkedin.com/company/esiea" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'linkedin-in']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
        <a href="https://www.youtube.com/user/ingenieuresiea" class="text-light" target="_blank"
          ><font-awesome-icon
            :icon="['fab', 'youtube']"
            size="lg"
            fixed-width
            class="mx-1"
        /></a>
      </b-col>
    </b-row>
    <Lightbox v-model="lightbox" />
  </b-container>
</template>

<script>
import Lightbox from "./Lightbox.vue";
export default {
  components: { Lightbox },

  data() {
    return {
      lightbox: null,
    };
  },

  methods: {
    link(url, event) {
      event.preventDefault();
      this.lightbox = url + "?autoplay=1";
    },
  },
};
</script>