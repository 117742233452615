import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BIcon, BIconCircleFill } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTwitter, faInstagram, faYoutube, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faComments, faEnvelope, faPlayCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faAngleLeft, faAngleRight, faArrowLeft, faBars, faBookOpen, faCircle, faExternalLinkAlt, faGamepad, faHome, faPhone, faPhoneVolume, faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';
import router from './router'
import axios from 'axios'
import Vuex from 'vuex'
import VueGtag from "vue-gtag";

library.add(faTwitter, faInstagram, faFacebookF, faLinkedinIn, faYoutube, faEnvelope, faAngleLeft, faQuestionCircle, faPlayCircle, faComments, faPhone, faGamepad, faQuestion, faBookOpen, faPhoneVolume, faCircle, faAngleRight, faHome, faBars, faArrowLeft, faTimes, faExternalLinkAlt);

Vue.component('font-awesome-icon', FontAwesomeIcon)

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.component('BIcon', BIcon)
Vue.component('BIconCircleFill', BIconCircleFill)

Vue.use(BootstrapVue)

Vue.use(Vuex)

Vue.use(VueGtag, {
  config: {
    id: window.location.hostname === "jpo.groupe.esiea.fr" ? "G-15Q18H4RXW" : "G-D2D2R24M2F",
    params: {
      allow_google_signals: false,
      allow_ad_personalization_signals: false,
      anonymize_ip: true
    }
  },
  pageTrackerScreenviewEnabled: true,
  appName: 'Mini site JPO'
});

const store = new Vuex.Store({
  state: {
    db: {}
  },
  mutations: {
    updateDb(state, db) {
      state.db = db;
    }
  },
  getters: {
    db(state) {
      return state.db
    }
  },
  actions: {
    async getDb({ commit }) {
      let response = await axios.get(process.env.VUE_APP_BACKEND_URL);
      commit('updateDb', response.data);
    }
  }
});

router.afterEach(() => {
  store.dispatch('getDb');
})

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
});

new Vue({
  router,
  store: store,
  created() {
    setInterval(() => {
      this.$store.dispatch('getDb')
    }, 10000)
  },
  render: h => h(App)
}).$mount('#app')
