<template>
  <b-modal id="lightbox" v-model="showLightbox" hide-footer centered size="xl" @close="onClose">
    <div class="embed-responsive embed-responsive-16by9">
      <iframe class="embed-responsive-item" :src="url" allowfullscreen></iframe>
    </div>
  </b-modal>
</template>
<script>
export default {
  model: {
    prop: "url",
    event: "change",
  },
  props: {
    url: String,
  },
  data() {
    return {
      showLightbox: false,
    };
  },

  watch: {
    url(url) {
      this.showLightbox = url !== null;
      if (this.showLightbox) {
        this.$gtag.event("lightbox_open", {
          event_label: this.url,
          event_category: "lightbox",
        });
      }
    },
    showLightbox(show) {
      if (!show) this.$emit("change", null);
    },
  },

  methods: {
    onClose() {
      if (this.showLightbox) {
        this.$gtag.event("lightbox_close", {
          event_label: this.url,
          event_category: "lightbox",
        });
      }
    }
  }
};
</script>
